import React, { useEffect, useState } from 'react';

import { Divider, Flex, Skeleton, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { ReactComponent as PlayCircle } from '../../../../assets/img/icons/faPlayCircle.svg';

import './index.css';

const ConsolidatedStatValueTag = ({
  stat,
  makeLink,
  selectedMetricFilters,
  selectedPositions,
  loadingPercentiles,
  showPth,
}: {
  stat: any;
  makeLink: any;
  selectedMetricFilters: any;
  selectedPositions: string | null;
  loadingPercentiles: boolean;
  showPth: boolean;
}) => {
  const navigate = useNavigate();
  const [displayedValue, setDisplayedValue] = useState(null);
  const [t] = useTranslation();
  const getMetricValue = () => {
    // console.log(selectedMetricFilters);
    if (!selectedPositions) {
      if (selectedMetricFilters.includes(`${stat.key}_success`)) {
        return stat.details[`${stat.key}_success`].total;
      }
      if (selectedMetricFilters.includes(`${stat.key}_fail`)) {
        return stat.details[`${stat.key}_fail`].total;
      }
    } else {
      if (stat?.details) {
        if (
          selectedMetricFilters.includes(`${stat.key}_success`) &&
          stat.details[`${stat.key}_success`]?.by_position &&
          stat.details[`${stat.key}_success`]?.by_position[selectedPositions]
        ) {
          return stat.details[`${stat.key}_success`]?.by_position[
            selectedPositions
          ].value;
        } else if (
          selectedMetricFilters.includes(`${stat.key}_fail`) &&
          stat.details[`${stat.key}_fail`]?.by_position &&
          stat.details[`${stat.key}_fail`]?.by_position[selectedPositions]
        ) {
          return stat.details[`${stat.key}_fail`]?.by_position[
            selectedPositions
          ].value;
        } else if (
          selectedMetricFilters.includes(`${stat.key}_success`) ||
          selectedMetricFilters.includes(`${stat.key}_fail`)
        ) {
          return 0;
        }
      }
      if (selectedPositions && stat?.by_position) {
        return stat.by_position[selectedPositions]?.value || 0;
      }
      return 0;
    }
    return stat.total;
  };
  useEffect(() => {
    console.log(selectedPositions);
    setDisplayedValue(getMetricValue());
  }, [selectedPositions, selectedMetricFilters, stat]);
  const getPercentValue = () => {
    if (!selectedPositions) {
      if (selectedMetricFilters.includes(`${stat.key}_success`)) {
        return '';
        // return stat.details[`${stat.key}_success`].percent + '%';
      }
      if (selectedMetricFilters.includes(`${stat.key}_fail`)) {
        return '';
        // return stat.details[`${stat.key}_fail`].percent + '%';
      }
      return stat.percent + '%';
    }
    if (stat?.details) {
      if (
        selectedMetricFilters.includes(`${stat.key}_success`) ||
        selectedMetricFilters.includes(`${stat.key}_fail`)
      ) {
        return '';
      }
      if (
        stat.by_position &&
        stat.by_position[selectedPositions] &&
        stat.by_position[selectedPositions]?.value
      ) {
        if (
          stat.details[`${stat.key}_success`]?.by_position !== null &&
          stat.details[`${stat.key}_success`]?.by_position !== undefined
        ) {
          const v =
            stat.details[`${stat.key}_success`]?.by_position[selectedPositions]
              ?.value || 0;
          return `${(
            (v / stat.by_position[selectedPositions].value) *
            100
          ).toFixed(0)}%`;
        }
        return '0%';
      }
      return '';
    }
  };
  const getPthValue = () => {
    if (stat.pth.details) {
      if (selectedMetricFilters.includes(`${stat.key}_success`)) {
        if (stat.pth.details[`${stat.key}_success`].pth) {
          return (
            stat.pth.details[`${stat.key}_success`].pth?.toString() + 'pth'
          );
        }
        return '';
      }
      if (selectedMetricFilters.includes(`${stat.key}_fail`)) {
        if (stat.pth.details[`${stat.key}_fail`].pth) {
          return stat.pth.details[`${stat.key}_fail`].pth?.toString() + 'pth';
        }
        return '';
      }
    }
    return stat.pth.pth + 'pth';
  };
  return (
    <>
      {displayedValue !== null ? (
        <Tooltip
          open={false}
          title={t('View episodes')}
          trigger={'hover'}
          placement="top"
        >
          <Flex
            className={`${displayedValue === undefined ? 'na-stat' : displayedValue > 0 ? 'stat-value-container enabledClickable' : 'stat-value-container-zero'}`}
            style={{ minWidth: 130, height: 38 }}
            justify={'space-between'}
            onClick={(e) => {
              e.stopPropagation();
              if (displayedValue) {
                navigate(makeLink(stat));
              }
            }}
          >
            <Flex gap={4} justify="flex-start" flex={1}>
              {displayedValue ? <PlayCircle height={38} /> : <></>}
              {displayedValue === undefined ? (
                <div
                  style={{ height: 38, paddingLeft: 12 }}
                  className={'flex-row j-ce ai-c disabled-stat-color'}
                >
                  {stat.status !== 'init' ? t('n/a') : ''}
                </div>
              ) : displayedValue > 0 ? (
                <div style={{ height: 38 }} className={'flex-row ai-c'}>
                  {displayedValue !== undefined
                    ? `${displayedValue}`
                    : stat.status !== 'init'
                      ? t('n/a')
                      : ''}
                </div>
              ) : (
                <span className="stat-average-value zero-value">
                  {displayedValue}
                </span>
              )}
            </Flex>
            {stat?.percent !== null && stat?.percent !== undefined && (
              <Flex
                justify="flex-start"
                align="center"
                flex={1}
                vertical={false}
                gap={8}
                style={{
                  height: 38,
                }}
              >
                <Divider
                  type="vertical"
                  style={{
                    height: 18,
                    width: 1,
                    margin: 0,
                    background: 'var(--colorPrimaryText, #8DC5F8)',
                  }}
                />
                <Flex
                  vertical
                  flex={1}
                  justify="center"
                  style={{
                    height: '100%',
                  }}
                  gap={0}
                >
                  {stat?.percent !== null && stat?.percent !== undefined ? (
                    <span
                      className={'stat-consolidated-precent-value'}
                      style={{ fontSize: 12, lineHeight: '16px' }}
                    >
                      {getPercentValue()}
                    </span>
                  ) : (
                    <span
                      className={'stat-consolidated-precent-value'}
                      style={{ fontSize: 12, lineHeight: '16px' }}
                    >
                      &nbsp;
                    </span>
                  )}
                  {loadingPercentiles ? (
                    <span
                      style={{
                        textTransform: 'none',
                        height: 12,
                      }}
                    >
                      <Skeleton.Button
                        active
                        shape={'round'}
                        style={{ minWidth: 40, width: 40, height: 11 }}
                      ></Skeleton.Button>
                    </span>
                  ) : stat?.pth !== null && stat?.pth !== undefined ? (
                    <span
                      className={'stat-percent-value'}
                      style={{
                        fontSize: 11,
                        lineHeight: '16px',
                        textTransform: 'none',
                      }}
                    >
                      {getPthValue()}
                    </span>
                  ) : (
                    showPth && (
                      <span
                        className={'stat-consolidated-precent-value'}
                        style={{ fontSize: 12, lineHeight: '16px' }}
                      >
                        &nbsp;
                      </span>
                    )
                  )}
                </Flex>
              </Flex>
            )}
          </Flex>
        </Tooltip>
      ) : (
        <Flex
          className={`${displayedValue === undefined ? 'na-stat' : displayedValue !== null && displayedValue > 0 ? 'stat-value-container enabledClickable' : 'stat-value-container-zero'}`}
          style={{ minWidth: 130, height: 38 }}
          justify={stat?.total !== undefined ? 'space-between' : 'center'}
          onClick={(e) => {
            e.stopPropagation();
            if (stat?.total) {
              navigate(makeLink(stat));
            }
          }}
        >
          {displayedValue ? <PlayCircle height={38} /> : <></>}
          {displayedValue === undefined ? (
            <div
              style={{ height: 38 }}
              className={'flex-row j-ce ai-c disabled-stat-color'}
            >
              {displayedValue !== undefined
                ? `${displayedValue}`
                : stat.status === 'init'
                  ? ''
                  : t('n/a')}
            </div>
          ) : displayedValue !== null && displayedValue > 0 ? (
            selectedPositions ? (
              <div style={{ height: 38 }} className={'flex-row ai-c'}>
                {stat.by_position &&
                stat.by_position[selectedPositions] !== null
                  ? `${stat?.by_position[selectedPositions]}`
                  : stat.status === 'init'
                    ? ''
                    : t('n/a')}
              </div>
            ) : (
              <div style={{ height: 38 }} className={'flex-row ai-c'}>
                {displayedValue !== undefined
                  ? `${displayedValue}`
                  : stat.status === 'init'
                    ? ''
                    : t('n/a')}
              </div>
            )
          ) : (
            <span className="stat-average-value zero-value">0</span>
          )}
          {stat?.percent !== null && stat?.percent !== undefined && (
            <>
              <Divider type="vertical" style={{ height: '100%', width: 1 }} />
              <Flex vertical gap={2}>
                {stat?.percent !== null && stat?.percent !== undefined && (
                  <span className={'stat-average-value'}>{stat?.percent}%</span>
                )}
                {loadingPercentiles ? (
                  <span
                    style={{
                      textTransform: 'none',
                      height: 12,
                    }}
                  >
                    <Skeleton.Button
                      active
                      shape={'round'}
                      style={{ minWidth: 40, width: 40, height: 11 }}
                    ></Skeleton.Button>
                  </span>
                ) : (
                  stat?.pth !== null &&
                  stat?.pth !== undefined && (
                    <span
                      className={'stat-percent-value'}
                      style={{
                        textTransform: 'none',
                      }}
                    >
                      {getPthValue()}
                    </span>
                  )
                )}
              </Flex>
            </>
          )}
        </Flex>
      )}
    </>
  );
};
export default ConsolidatedStatValueTag;
