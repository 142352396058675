import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import { AC_FileUploadInProgress } from 'actions/calendar.actions';
import { EventsAPI } from 'api/events';
import { ReactComponent as CameraOutlineIcon } from 'assets/img/CameraOutlineIcon.svg';
import { ReactComponent as ClearCrossIcon } from 'assets/img/clearCrossIcon.svg';
import { ReactComponent as DeleteIconRed } from 'assets/img/DeleteIconRed.svg';
import { ReactComponent as UploadBlueIcon } from 'assets/img/UploadBlue.svg';
import { ReactComponent as UploadGreyIcon } from 'assets/img/UploadGrey.svg';
import { AppStateType } from 'reducers';
import { useAppDispatch } from 'store';
// import { MAX_UPLOAD_VIDEO_SIZE } from 'types/constants';
import { VideoFileType } from 'types/types';

import './index.css';
import ConfirmActionModal, {
  StyleOptions,
} from '../../modals/ConfirmActionModal';
import WarningModal from '../../modals/WarningModal';

export const progressStatus = {
  exception: 'exception',
  normal: 'normal',
  active: 'active',
  success: 'success',
};
interface Interface {
  fileNames: Array<VideoFileType>;
  setFileNames: any;
  successCallback?: any;
  deleteCallback?: any;
  tooltipId?: string;
  activityId: any;
}

const VideoUploadController: FC<Interface> = ({
  fileNames,
  setFileNames,
  successCallback,
  deleteCallback,
  tooltipId = 'edit',
  activityId,
}) => {
  const controllerRef = useRef<AbortController | null>(null);
  // const cancelRef = useRef<string | null>(null);
  const { fileUploadInProgress } = useSelector(
    (state: AppStateType) => state.calendarReducer,
  );
  const dispatch = useAppDispatch();
  const [videoRecordToRemoveIndex, setVideoRecordToRemoveIndex] = useState<
    number | null
  >(null);
  // const [fileTooBigError, setFileTooBigError] = useState<boolean>(false);
  const [fileAlreadyExists, setFileAlreadyExists] = useState<boolean>(false);
  const [showUploadError, setShowUploadError] = useState<boolean>(false);
  const tempFileList: Array<any> = [];
  const [fileUploadErrorTexts, setFileUploadErrorTexts] = useState<{
    [key: string]: string;
  }>({});
  useEffect(() => {
    if (fileUploadInProgress) {
      const filteredFileList = fileNames
        ?.filter((file) => file.status === progressStatus.normal)
        ?.filter((file) => !file.isSent);
      // fileUpload(filteredFileList);
      fileUploadVersion3(filteredFileList).then();
    }
  }, [fileUploadInProgress]);
  const CHUNK_SIZE = 6 * 1024 * 1024;

  const fileUploadVersion3 = async (filteredFileList: Array<any>) => {
    for (const file of filteredFileList) {
      controllerRef.current = new AbortController();
      const initResponse: any = await EventsAPI.getUploadId(
        activityId,
        file.name,
      );
      const { id, upload_id, file_path_within_bucket } = initResponse.data;

      const numParts = Math.ceil(file.size / CHUNK_SIZE);
      const uploadedParts = [];
      console.log('parts');
      console.log(numParts);
      for (let partNumber = 1; partNumber <= numParts; partNumber++) {
        const start = (partNumber - 1) * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const blob = file.slice(start, end);

        const presignedResponse: any = await EventsAPI.getMultipartPresignedUrl(
          activityId,
          file_path_within_bucket,
          'application/octet-stream',
          upload_id,
          partNumber,
        );

        const { url } = presignedResponse.data;

        // Step 4: Upload the file chunk to the pre-signed URL
        const uploadResponse: any = await EventsAPI.uploadVideoFileV2(
          url,
          blob,
          false,
          setFileProgress,
          controllerRef.current,
        );
        console.log(uploadedParts);
        uploadedParts.push({
          ETag: uploadResponse.headers.etag,
          PartNumber: partNumber,
        });
      }
      await EventsAPI.completeMultipartUpload(
        file_path_within_bucket,
        upload_id,
        uploadedParts,
        id,
      );
      successCallback(file);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fileUpload = (filteredFileList: Array<VideoFileType>) => {
    for (const file of filteredFileList) {
      controllerRef.current = new AbortController();

      EventsAPI.getVideoPresignedUrl(
        activityId,
        file.name,
        'application/octet-stream',
      ).then((res: any) => {
        // file.name = res.data.encoded_file_name;
        EventsAPI.uploadVideoFileV2(
          res.data.url,
          file,
          'application/octet-stream',
          setFileProgress,
          controllerRef.current,
        )
          .then(() => {
            file.status = progressStatus.success;
            file.id = res.data.id;
            file.percent = 100;
            updateUploadingFiles(file);
            dispatch(AC_FileUploadInProgress(false));
            if (successCallback) {
              successCallback(file);
            }
          })
          .catch((error: any) => {
            file.status = progressStatus.exception;
            file.percent = 0;
            file.error = error.response?.data?.error;
            updateUploadingFiles(file);
            dispatch(AC_FileUploadInProgress(false));
            if (error.code !== 'ERR_CANCELED') {
              setShowUploadError(true);
              setFileUploadErrorTexts({
                ...fileUploadErrorTexts,
                [file.name]: file.error || '',
              });
            }
          });
      });

      // EventsAPI.uploadVideoFile(file, setFileProgress, controllerRef.current)
      //   .then((response: any) => {
      //     file.status = progressStatus.success;
      //     file.id = response.data.record_uuid;
      //     file.percent = 100;
      //     updateUploadingFiles(file);
      //     dispatch(AC_FileUploadInProgress(false));
      //     if (successCallback) {
      //       successCallback(file);
      //     }
      //   })
      //   .catch((error: any) => {
      //     file.status = progressStatus.exception;
      //     file.percent = 0;
      //     file.error = error.response?.data?.error;
      //     updateUploadingFiles(file);
      //     dispatch(AC_FileUploadInProgress(false));
      //     if (error.code !== 'ERR_CANCELED') {
      //       setShowUploadError(true);
      //       setFileUploadErrorTexts({
      //         ...fileUploadErrorTexts,
      //         [file.name]: file.error || '',
      //       });
      //     }
      //   });
    }
  };
  const updateUploadingFiles = (file: VideoFileType, retry = false) => {
    // console.log('updateUploadingFiles')
    const newFile = file;
    const isValid = true;
    if (fileNames.find((item) => item.id === file.id)) {
      // console.log('updateUploadingFiles setfilenames')
      setFileNames((prev: any) =>
        prev.map((el: any) => (el.name === file.name ? file : el)),
      );
      // console.log('fileNames', fileNames);
    } else {
      newFile.isSent = false;
      newFile.status = isValid
        ? progressStatus.normal
        : progressStatus.exception;
    }
    // console.log('updateUploadingFiles isvalid', isValid)
    retry && isValid && dispatch(AC_FileUploadInProgress(true));
    return file;
  };
  const setFileProgress = (
    file: VideoFileType,
    info: any,
    progress: number | undefined = undefined,
  ) => {
    // console.log('SETTING UPLOAD PROGRESS info', info)
    if (progress !== undefined) {
      const newFile: VideoFileType = file;
      newFile.percent = progress;
      newFile.status = progressStatus.active;
      // console.log('SETTING UPLOAD PROGRESS newFile', newFile)
      updateUploadingFiles(newFile);
    } else if (info) {
      const { loaded, total } = info;
      const perc = Math.floor((loaded * 100) / total);
      // console.log('SETTING UPLOAD PROGRESS perc', perc)
      const newFile: VideoFileType = file;
      newFile.percent = perc;
      newFile.status = progressStatus.active;
      // console.log('SETTING UPLOAD PROGRESS newFile', newFile)
      updateUploadingFiles(newFile);
    }
  };
  const validateFile = (file: any) => {
    // if (file?.size > MAX_UPLOAD_VIDEO_SIZE) {
    //   setFileTooBigError(true);
    //   setTimeout(() => {
    //     setFileTooBigError(false);
    //   }, 4000);
    //   return false;
    // }
    if (fileNames.filter((el) => file.name === el.name).length > 0) {
      setFileAlreadyExists(true);
      setTimeout(() => {
        setFileAlreadyExists(false);
      }, 4000);
      return false;
    }
    return true;
  };
  const videoUploadRef = useRef<any>(null);
  const fileUploadRef = useRef<any>(null);

  const getVideoFrameRate = (videoElement: any) => {
    let frameRate = null;

    // Use `requestVideoFrameCallback` if available to detect frame rate
    if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
      videoElement.requestVideoFrameCallback((now: any, metadata: any) => {
        if (metadata.presentedFrames > 1) {
          frameRate = metadata.presentedFrames / (now / 1000);
        }
      });
    }

    // Return an assumed frame rate if not detectable (default 30fps)
    return frameRate || 30;
  };
  const updateFilesList = (e: any) => {
    const newFile = e.target.files[0];
    const isValid = validateFile(newFile);
    newFile.isSent = false;
    newFile.status = isValid ? progressStatus.normal : progressStatus.exception;
    if (isValid) {
      tempFileList.push(newFile);
      const videoElement = videoRef.current;
      const fileURL = URL.createObjectURL(newFile);
      if (videoRef && videoElement) {
        videoElement.src = fileURL;
        videoElement.onloadedmetadata = () => {
          const videoDuration = videoElement.duration; // Duration in seconds

          // Check if we can get frame rate directly (not all browsers support this)
          const frameRate = getVideoFrameRate(videoElement);
          newFile.meta = {
            initial_file_name: newFile.name,
            fps: frameRate,
            video_length: videoDuration.toString(),
            width: videoElement.videoWidth,
            height: videoElement.videoHeight,
          };
        };
      }
      if (tempFileList.length > 0) {
        setFileNames((prevList: any) => [...prevList, newFile]);
      }

      dispatch(AC_FileUploadInProgress(true));
    }
  };
  const makeFileNameDisplay = (file: VideoFileType) => {
    // return file.name.slice(-20);
    if (file.meta) {
      return file.meta.initial_file_name;
    } else {
      return file.name;
    }
  };
  const cancelUpload = () => {
    const controller = controllerRef.current;
    controller && controller.abort();
  };
  const removeFileFromList = (index: number) => {
    // Исключение файла из списка текущих на фронте
    cancelUpload();
    const deletedFile = fileNames[index];
    const newList = [
      ...fileNames.slice(0, index),
      ...fileNames.slice(index + 1),
    ];
    setFileNames(newList);
    setVideoRecordToRemoveIndex(null);
    setConfirmRemove(true);
    if (deletedFile.id && deleteCallback !== undefined) {
      deleteCallback(deletedFile.id);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [confirmRemove, setConfirmRemove] = useState(false);
  const confirmRemoveVideoRecord = () => {
    // Подтверждение удаления видео файла
    if (videoRecordToRemoveIndex !== null) {
      removeFileFromList(videoRecordToRemoveIndex);
      setConfirmRemove(true);
    }
    setVideoRecordToRemoveIndex(null);
  };
  const [t] = useTranslation();
  const uploadedFilesRefs = useRef<any>({});
  useLayoutEffect(() => {
    const allRefs = { ...uploadedFilesRefs.current };
    const remainingRefs: any = {};
    for (const fileName of Object.keys(allRefs)) {
      if (Object.keys(fileUploadErrorTexts).includes(fileName)) {
        remainingRefs[fileName] = allRefs[fileName];
      }
    }
    uploadedFilesRefs.current = remainingRefs;
  }, [fileUploadErrorTexts]);

  const removeFileFromUploadList = (file: any, index: number) => {
    if (file.error) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [file.name]: dev, ...allErrors } = { ...fileUploadErrorTexts };
      setFileUploadErrorTexts(allErrors);
      removeFileFromList(index);
    } else {
      setConfirmRemove(true);
      setVideoRecordToRemoveIndex(index);
    }
  };
  const videoRef = useRef<any>(null);
  const additionalProps = (text: string) => {
    if (text.length > 20) {
      return {
        'data-tooltip-id': `fileNameTooltip-${tooltipId}`,
        'data-tooltip-content': text,
        'data-tooltip-place': 'bottom',
        'data-tooltip-delay-hide': 1000,
      };
    }
  };
  return (
    <div className={'videoUploaderContainer'}>
      <Tooltip
        variant={'dark'}
        id={`fileNameTooltip-${tooltipId}`}
        noArrow={false}
        className={'videoAttributesTooltip'}
        place={'bottom-end'}
      />
      {fileNames.length > 0 && (
        <>
          {fileNames?.map((file: VideoFileType, index: number) => {
            return (
              <div
                key={index}
                {...(additionalProps(file?.name) as {
                  [key: string]: string | number;
                })}
                className={'flex-row fileListItem'}
                ref={(el) => {
                  if (
                    !Object.keys(uploadedFilesRefs.current).includes(file.name)
                  ) {
                    uploadedFilesRefs.current[file.name] = el;
                  }
                }}
              >
                <div
                  className={`flex-row f-ga-8 ${fileUploadInProgress && file.status === progressStatus.active ? '' : 'disabled'}`}
                  style={{ maxWidth: '75%' }}
                >
                  {file.camera ? (
                    <CameraOutlineIcon
                      style={{ width: '24px', height: '24px' }}
                    />
                  ) : fileUploadInProgress &&
                    file.status === progressStatus.active ? (
                    <UploadBlueIcon style={{ width: '24px', height: '24px' }} />
                  ) : (
                    <UploadGreyIcon style={{ width: '24px', height: '24px' }} />
                  )}
                  <div className="elipsis-text" style={{ maxWidth: '85%' }}>
                    {makeFileNameDisplay(file)}
                  </div>
                </div>
                <div className="flex-row f-ga-8">
                  {file.percent !== undefined && (
                    <div
                      className={`${fileUploadInProgress && file.status === progressStatus.active ? '' : 'disabled'}`}
                    >
                      {file.percent}%
                    </div>
                  )}
                  {fileUploadInProgress &&
                  file.status === progressStatus.active ? (
                    <div>
                      <ClearCrossIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => removeFileFromList(index)}
                      />
                    </div>
                  ) : (
                    <div>
                      <DeleteIconRed
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          removeFileFromUploadList(file, index);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}
      <div
        className={'timeZoneSelector enabledClickable'}
        onClick={() => {
          videoUploadRef && videoUploadRef?.current?.click();
        }}
        ref={fileUploadRef}
      >
        <input
          type="file"
          ref={videoUploadRef}
          style={{ display: 'none' }}
          multiple={false}
          onChange={(e: any) => {
            updateFilesList(e);
          }}
        />
        <UploadBlueIcon />
        &nbsp;&nbsp;{t('Upload video or markup')}
        <video ref={videoRef} style={{ display: 'none' }} />
      </div>
      <ConfirmActionModal
        title={t('Delete record?')}
        contentText={t('You cannot restore record.')}
        style={StyleOptions.warning}
        actionText={t('Delete')}
        actionCallback={confirmRemoveVideoRecord}
        isModalOpen={videoRecordToRemoveIndex !== null}
        setIsModalOpen={() => setVideoRecordToRemoveIndex(null)}
      />
      {/* {fileTooBigError && (
        <WarningModal
          title={`${t('File size should not exceed ')} ${MAX_UPLOAD_VIDEO_SIZE / 1024 / 1024}${t('Mb')}`}
          referencedField={fileUploadRef.current.getBoundingClientRect()}
        />
      )} */}
      {fileAlreadyExists && (
        <WarningModal
          title={`${t('File with this name already uploaded to this activity')}`}
          referencedField={{
            top:
              fileUploadRef?.current.offsetTop +
              fileUploadRef?.current.getBoundingClientRect().height,
            left: fileUploadRef?.current.offsetLeft,
            width: fileUploadRef?.current.width,
          }}
        />
      )}
      {showUploadError &&
        Object.keys(fileUploadErrorTexts).map(
          (fileName: string, index: number) => (
            <WarningModal
              key={index}
              title={`${fileUploadErrorTexts[fileName]} ${fileName.slice(-10)}`}
              referencedField={uploadedFilesRefs.current[
                fileName
              ]?.getBoundingClientRect()}
            />
          ),
        )}
    </div>
  );
};
export default VideoUploadController;
